import React from "react";
import Link from "gatsby-link";
import "./style.scss";
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import useSound from 'use-sound';
import boopSfx from '../../sounds/pop-down.mp3';

const Navbar = () => {
  // const [isChecked, setIsChecked] = React.useState(
  //   false
  // );
  const [playActive] = useSound(boopSfx);
  const [playOn] = useSound(boopSfx);
  return (
    <div className="navbar-wrapper fixed-top">
    <nav className="navbar">
      <div className="top-menu">
        <div className="top-name">
          <Link to="/" className="name-link">
          A<span>.</span>
          </Link>
         
        </div>
        <div className="navbar-nav-scroll">

          <ul className="navbar-nav bd-navbar-nav flex-row">
          <li
              className="nav-item"  
            >
              <a href="/#apie" className="nav-link" activeClassName="active">
                Apie Mane
              </a>
            </li>
            <li
              className="nav-item"  
            >
              <a href="/#paslaugos" className="nav-link" activeClassName="active">
                Paslaugos
              </a>
            </li>
            <li
              className="nav-item"
            >
              <Link to="/kontaktai/" className="nav-link" activeClassName="active">
                Kontaktai
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    </div>
  );
};

export default Navbar;
